import React, { useState, useEffect } from "react";
import {  BsWhatsapp } from "react-icons/bs";
import {  AiOutlineClose } from "react-icons/ai";
import AssCMensal from "./P06AssCMensal";
import AssCAnual from "./P06AssCAnual";


function Suporte({onClose, plano, email }){
    
    function abrirLink() {
        window.open('https://wa.me/5561994363956', '_blank');
      }

    return(

        <div id="suportecard" className="bgAcao" style={{ fontFamily:'montserrat'}}>
            <div className="cardAss">
                
                {/*<img className='AssCantoA'
                    src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus%202.svg?alt=media&token=efb9dc0a-e0e0-4eb2-98ed-41056c4e2c0b&_gl=1*i7hoic*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NzE0MDYwNi4yMzYuMS4xNjk3MTQwNzc1LjI3LjAuMA..'
                    alt=''/>
                <img className='AssCantoD'
                    src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fglifo%20ktalus%202.svg?alt=media&token=efb9dc0a-e0e0-4eb2-98ed-41056c4e2c0b&_gl=1*i7hoic*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NzE0MDYwNi4yMzYuMS4xNjk3MTQwNzc1LjI3LjAuMA..'
                    alt=''/>*/}

<div className="closePlan" onClick={onClose}><AiOutlineClose/></div>

<div className="ass-modoMensal" >
            
            <div className="suporte--modo">
            <BsWhatsapp style={{color:'#dffc01', fontSize:'80px'}}/>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'4px', color:'#dffc01', fontSize:'24px', fontWeight:700, fontFamily:'montserrat'}}>Suporte Ktalus</div>
    
               
            </div>
    
            
    
           
    
            <div style={{width:'100%', display:'flex', justifyContent:'center', fontSize:'16px', fontFamily:'montserrat'}}>Se estiver tendo dificuldade na <br/>plataforma ou precisa tirar alguma <br/>dúvida, contate nosso Suporte, <br/>clicando no botão abaixo.</div>
    
            <div className="addTattoo" onClick={abrirLink} style={{marginTop:'24px', marginBottom:'8px'}}>WhatsApp Suporte</div>
            
            <div style={{fontSize:'12px', color:'rgba(255,255,255,0.5)', fontFamily:'montserrat'}}> De segunda a sexta(exceto feriados)<br/>
                9h as 12h /14h as 18h.</div>
    
        </div>




                

               

     
         </div> </div>
    )
}


export default Suporte;