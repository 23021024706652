import React, { useEffect, useState } from 'react';

import { BiEdit } from "react-icons/bi";
import { FaCircleCheck } from "react-icons/fa6";
import { getImageURL } from '../../register/GlifosImg'; 
import { doc, updateDoc } from "firebase/firestore"; 
import { db } from '../../../services/firebaseConfig';

function P05PersonalizarDK({  estudio, setComponenteAtivo, tema, fonte, estilo, nome, perfil }){

    const [corState, setCorState] = useState(tema);
    const [fonteState, setFonteState] = useState(fonte);
    const [estiloState, setEstiloState] = useState(estilo);

    const [editCor, setEditCor] = useState(false);
    const [editFonte, setEditFonte] = useState(false);
    const [editEstilo, setEditEstilo] = useState(false);

    const fontPage = {
        roboto: 'fontRoboto', slab: 'fontSlab', serif: 'fontSerif', alegreya: 'fontAlegreya', suse: 'fontSuse', rajdhani: 'fontRajdhani',
        playpen: 'fontPlaypen', josefin:'fontJosefin', playfair:'fontPlayfair', nunito:'fontNunito'
      };
    
      const colorPage = {
        vermelho:'pagePadrao', laranja:'pagePadrao', amarelo:'pagePadrao', verde:'pagePadrao', ciano:'pagePadrao',
        azul:'pagePadrao', roxo:'pagePadrao', rosa:'pagePadrao', magenta:'pagePadrao', branco:'pagePadrao',
    
        goldVermelho: 'pageVer', goldVerde:'pageVerd', goldAzul:'pageAzu', goldRoxo:'pageRox', goldAmarelo:'pageAma',
    
        silverVermelho: 'pageVer', silverVerde:'pageVerd', silverAzul:'pageAzu', silverRoxo:'pageRox', silverAmarelo:'pageAma',
    
        vermelhoVerde:'pageVerd', laranjaCiano:'pageCia', verdeVermelho:'pageVer', azulAmarelo:'pageAma',
        
        
      };

      const colorPage2 = {
        vermelho:'pagePadrao bVer', laranja:'pagePadrao bLan', amarelo:'pagePadrao bAma', verde:'pagePadrao bVerd', ciano:'pagePadrao bCia',
        azul:'pagePadrao bAzu', roxo:'pagePadrao bRox', rosa:'pagePadrao bRos', magenta:'pagePadrao bMag', branco:'pagePadrao bBra',
    
        goldVermelho: 'pageVer bAma', goldVerde:'pageVerd bAma', goldAzul:'pageAzu bAma', goldRoxo:'pageRox bAma', goldAmarelo:'pageAma bAma',
    
        silverVermelho: 'pageVer bBra', silverVerde:'pageVerd bBra', silverAzul:'pageAzu bBra', silverRoxo:'pageRox bBra', silverAmarelo:'pageAma bBra',
    
        vermelhoVerde:'pageVerd bVer', laranjaCiano:'pageCia bLan', verdeVermelho:'pageVer bVerd', azulAmarelo:'pageAma bAzu',
        
        
      };
    
      const colorBorder = {
        vermelho:'bVer', laranja:'bLan', amarelo:'bAma', verde:'bVerd', ciano:'bCia',
        azul:'bAzu', roxo:'bRox', rosa:'bRos', magenta:'bMag', branco:'bBra',
    
        goldVermelho: 'bAma', goldVerde:'bAma', goldAzul:'bAma', goldRoxo:'bAma', goldAmarelo:'bAma',
    
        silverVermelho: 'bBra', silverVerde:'bBra', silverAzul:'bBra', silverRoxo:'bBra', silverAmarelo:'bBra',
    
        vermelhoVerde:'bVer', laranjaCiano:'bLan', verdeVermelho:'bVerd', azulAmarelo:'bAzu',
        
        
      };

      const estiloK = {
        estiloN:'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Festilos%2F01%20none.png?alt=media&token=8f2ae227-56e9-480e-ada2-16274cde4446&_gl=1*14hlihh*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4MDkxOC4xOTUuMS4xNjk2MjgyMjAwLjYwLjAuMA..',
        estilo1:'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fst1.svg?alt=media&token=4f6af557-6ee0-4e35-a2fa-9d85734c5669',
        estilo2:'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fst2.svg?alt=media&token=87606c53-1ae1-421a-9f02-92480b5a95d6',
        estilo3:'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fst3.svg?alt=media&token=5e12306e-a64a-439b-82cb-58bd37e464fa',
        estilo4: 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4.svg?alt=media&token=231c33d9-f345-4e65-92b4-e19d4ae9c3bf',
        estilo5: 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5.svg?alt=media&token=5baed75c-d347-43f9-aca3-6c39cedd31a3',
        estilo6: 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6.svg?alt=media&token=55dfe06d-004e-474c-bd82-ae3ea32a491b',
        estilo7: 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7.svg?alt=media&token=fab04978-d860-4d2f-a306-2f7d189bc2ad',
        estilo8: 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8.svg?alt=media&token=d3b59a47-78d3-43b4-8eac-c481ed008f1c',
        estilo9: '',
        
      }
    
      const atualizarTemaFirestore = async () => {
        try {
            const estudioRef = doc(db, "estudio", estudio); // Referência para o documento
            await updateDoc(estudioRef, {
                tema: corState,  // Supondo que temaState contém o novo valor para o campo 'tema'
            });
            
            console.log("Documento atualizado com sucesso!");
            
            // Seta o estado 'editCor' como false após atualizar o documento com sucesso
            setEditCor(false);
        } catch (error) {
            console.error("Erro atualizando documento: ", error);
        }
    };

    const atualizarFonteFirestore = async () => {
        try {
            const estudioRef = doc(db, "estudio", estudio); // Referência para o documento
            await updateDoc(estudioRef, {
                fonte: fonteState,  // Supondo que temaState contém o novo valor para o campo 'tema'
            });
            
            console.log("Documento atualizado com sucesso!");
            
            // Seta o estado 'editCor' como false após atualizar o documento com sucesso
            setEditFonte(false);
        } catch (error) {
            console.error("Erro atualizando documento: ", error);
        }
    };

    const atualizarEstiloFirestore = async () => {
        try {
            const estudioRef = doc(db, "estudio", estudio); // Referência para o documento
            await updateDoc(estudioRef, {
                estilo: estiloState,  // Supondo que temaState contém o novo valor para o campo 'tema'
            });
            
            console.log("Documento atualizado com sucesso!");
            
            // Seta o estado 'editCor' como false após atualizar o documento com sucesso
            setEditEstilo(false);
        } catch (error) {
            console.error("Erro atualizando documento: ", error);
        }
    };
    
      
      


    return( 
        <div className='bgly' style={{ borderRadius:'16px',fontFamily:'montserrat', display:'flex', flexDirection:'column', width:'100%', margin:'24px', padding:'16px'}}>
             <div style={{fontSize:'24px', color:'#dffc01', fontWeight:700, textAlign:'left', marginBottom:'16px'}}> Personalizar
          <hr/>
          </div>
         
            <div className='' style={{ display: 'flex', flexDirection:'column', width:'100%', gap:'8px'}}>

                <div className='bgly' style={{borderRadius:'16px', color:'#fff', padding:'16px',fontSize:'24px', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'start'}}>
                    <span style={{ color:'#fff', fontWeight:'700'}}>Preview</span>
                    <div className='previewcontainer' style={{fontFamily: fonteState === 'roboto' ? 'Roboto' : fonteState === 'slab' ? 'Roboto Slab' :  fonteState === 'serif' ? 'Roboto serif' : fonteState === 'alegreya' ? 'Alegreya': fonteState === 'rajdhani' ? 'Rajdhani': fonteState === 'playpen' ? 'Playpen Sans': fonteState === 'suse' ? 'SUSE': fonteState === 'josefin' ? 'Josefin Sans': fonteState === 'playfair' ? 'Playfair Display': fonteState === 'nunito' ? 'Nunito': ''}}>
                        <div  className={`previewPerfilPerso ${corState ? colorPage[corState] || '' : ''}`}>
                            <div  className={`previewPerfilb ${corState ? colorBorder[corState] || '' : ''}`}></div>
                            
                            <img className='Prev--cantoD' style={{ display: estiloState === 'estiloN' ? 'none' : 'flex' }}
                            src={getImageURL(estiloState, corState)} alt='' />

                            <img className='Prev--cantoC' style={{ display: estiloState === 'estiloN' ? 'none' : 'flex' }}
                            src={getImageURL(estiloState, corState)} alt='' />

                            <img className='Prev--cantoB' style={{ display: estiloState === 'estiloN' ? 'none' : 'flex' }}
                            src={getImageURL(estiloState, corState)} alt=''/>

                            <img className='Prev--cantoA' style={{ display: estiloState === 'estiloN' ? 'none' : 'flex' }}
                            src={getImageURL(estiloState, corState)} alt=''/>




                            <div  className={`prepreviewPerfil-borda ${corState ? colorBorder[corState] || '' : ''}`} >
                                <img className="prepreviewPerfil-foto" src={perfil || 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fnew%2Fperfil%20off.svg?alt=media&token=59b40760-ce6a-48e8-a0ba-35d2e9adcbbf' } alt="" background="#fff"/>
                            </div>
                            {nome}
                            </div>

                    </div>
                </div>

                <div className='bgly' style={{borderRadius:'16px', color:'#fff', padding:'16px', display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'start'}}>
                        <div className='descricaotittle' style={{ fontSize:'24px', height:'45px', gap:'16px'}}>
                            Cor
                            {!editCor && <div className={`personalizar--cor ${corState ? colorPage2[corState] || '' : ''}`}></div>}
                            {!editCor && <div className='nomeContainer--buttonEditDK' onClick={() => { setEditCor(true); setEditFonte(false); setEditEstilo(false); }}>Editar<BiEdit style={{ fontSize: '18px' }} /></div>}

                        </div> 

                        
                       
                    {editCor && <div className='persoBoxEdit'>

                    <div className="cores" style={{fontWeight:400, height:'316px', gap:'0px', width:'100%'}}>
                  
                        <div className="cores--estilo" >
                        Mono Dark
                            <div className="cores--cores">
                                
                                <div id="vermelho" onClick={()=>setCorState('vermelho')}  style={{border: '2px solid #FF0000' ,background:'linear-gradient(to top, #191919, #262626)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === 'vermelho' && <FaCircleCheck />}</div>
                                <div id="laranja" onClick={()=>setCorState("laranja")}  style={{border: '2px solid #FF8000' ,background:'linear-gradient(to top, #191919, #262626)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === "laranja" && <FaCircleCheck />}</div>
                                <div id="amarelo" onClick={()=>setCorState("amarelo")}  style={{border: '2px solid #FFD400' ,background:'linear-gradient(to top, #191919, #262626)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === "amarelo" && <FaCircleCheck />}</div>
                                <div id="verde" onClick={()=>setCorState("verde")}  style={{border: '2px solid #00FF00' ,background:'linear-gradient(to top, #191919, #262626)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === "verde" && <FaCircleCheck style={{color:'#00FF00' }} />}</div>
                                <div id="ciano" onClick={()=>setCorState("ciano")}  style={{border: '2px solid #00FFFF' ,background:'linear-gradient(to top, #191919, #262626)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === "ciano" && <FaCircleCheck />}</div>
                                <div id="azul" onClick={()=>setCorState("azul")}  style={{border: '2px solid #0080FF' ,background:'linear-gradient(to top, #191919, #262626)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === "azul" && <FaCircleCheck />}</div>
                                <div id="roxo" onClick={()=>setCorState("roxo")}  style={{border: '2px solid #AA00FF' ,background:'linear-gradient(to top, #191919, #262626)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === "roxo" && <FaCircleCheck />}</div>
                                <div id="rosa" onClick={()=>setCorState("rosa")}  style={{border: '2px solid #FF00D4' ,background:'linear-gradient(to top, #191919, #262626)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === "rosa" && <FaCircleCheck />}</div>
                                <div id="magenta"  onClick={()=>setCorState("magenta")} style={{border: '2px solid #FF0055' ,background:'linear-gradient(to top, #191919, #262626)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === "magenta" && <FaCircleCheck />}</div>
                                <div id="branco" onClick={()=>setCorState("branco")}  style={{border: '2px solid #CCCCCC' ,background:'linear-gradient(to top, #191919, #262626)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === "branco" && <FaCircleCheck />}</div>
                            
                            </div>

                        </div>

                        {/*<div className="cores--estilo" >
                        Mono Light
                            <div className="cores--cores">
                                
                                <div id="vermelho" onClick={()=>setCorState('vermelho')}  style={{border: '4px solid #E50000' ,background:'linear-gradient(to top, #190000, #330000)',color:'#FFf'}} className="Color">{corState === 'vermelho' && <FaCircleCheck style={{color:'#E50000' }} />}</div>
                                <div id="laranja" onClick={()=>setCorState("laranja")}  style={{border: '4px solid #E57300' ,background:'#EEEFF1',color:'#FFf'}} className="Color">{corState === "laranja" && <FaCircleCheck style={{color:'#E57300' }}/>}</div>
                                <div id="amarelo" onClick={()=>setCorState("amarelo")}  style={{border: '4px solid #E5BF00' ,background:'#EEEFF1',color:'#FFf'}} className="Color">{corState === "amarelo" && <FaCircleCheck style={{color:'#E5BF00' }}/>}</div>
                                <div id="verde" onClick={()=>setCorState("verde")}  style={{border: '4px solid #009900' ,background:'#EEEFF1',color:'#FFf'}} className="Color">{corState === "verde" && <FaCircleCheck style={{color:'#009900' }}/>}</div>
                                <div id="ciano" onClick={()=>setCorState("ciano")}  style={{border: '4px solid #00B2B2' ,background:'#EEEFF1',color:'#FFf'}} className="Color">{corState === "ciano" && <FaCircleCheck style={{color:'#00B2B2' }}/>}</div>
                                <div id="azul" onClick={()=>setCorState("azul")}  style={{border: '4px solid #0066CC' ,background:'#EEEFF1',color:'#FFf'}} className="Color">{corState === "azul" && <FaCircleCheck style={{color:'#0066CC' }}/>}</div>
                                <div id="roxo" onClick={()=>setCorState("roxo")}  style={{border: '4px solid #8800CC' ,background:'#EEEFF1',color:'#FFf'}} className="Color">{corState === "roxo" && <FaCircleCheck style={{color:'#8800CC' }}/>}</div>
                                <div id="rosa" onClick={()=>setCorState("rosa")}  style={{border: '4px solid #CC00AA' ,background:'#EEEFF1',color:'#FFf'}} className="Color">{corState === "rosa" && <FaCircleCheck style={{color:'#CC00AA' }}/>}</div>
                                <div id="magenta"  onClick={()=>setCorState("magenta")} style={{border: '4px solid #CC1954' ,background:'#EEEFF1',color:'#FFf'}} className="Color">{corState === "magenta" && <FaCircleCheck style={{color:'#CC1954' }}/>}</div>
                                <div id="branco" onClick={()=>setCorState("branco")}  style={{border: '4px solid #191919' ,background:'#EEEFF1',color:'#FFf'}} className="Color">{corState === "branco" && <FaCircleCheck style={{color:'#191919' }}/>}</div>
                            
                            </div>

                        </div>*/}

                        <div style={{ display:'flex', flexDirection: 'row'}}>
                        <div className="cores--estilo">
                        Gold
                            <div className="cores--cores">
                                
                                <div id="goldVermelho" onClick={()=>setCorState("goldVermelho")} style={{border: '2px solid #FFD400' ,background:'linear-gradient(to top, #190000, #330000)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === "goldVermelho" && <FaCircleCheck />}</div>
                                <div id="goldVerde" onClick={()=>setCorState("goldVerde")} style={{border: '2px solid #FFD400' ,background:'linear-gradient(to top, #001900, #003300)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === "goldVerde" && <FaCircleCheck />}</div>
                                <div id="goldAzul" onClick={()=>setCorState("goldAzul")} style={{border: '2px solid #FFD400' ,background:'linear-gradient(to top, #000D19, #001A33)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === "goldAzul" && <FaCircleCheck />}</div>
                                <div id="goldRoxo" onClick={()=>setCorState("goldRoxo")} style={{border: '2px solid #FFD400' ,background:'linear-gradient(to top, #110019, #220033)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === "goldRoxo" && <FaCircleCheck />}</div>
                                <div id="goldAmarelo" onClick={()=>setCorState("goldAmarelo")} style={{border: '2px solid #FFD400' ,background:'linear-gradient(to top, #191500, #332B00)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === "goldAmarelo" && <FaCircleCheck />}</div>
                            
                                

                            </div>

                        </div>

                        <div className="cores--estilo">
                        Silver
                            <div className="cores--cores">
                                
                                <div id="silverVermelho" onClick={()=>setCorState("silverVermelho")} style={{border: '2px solid #CCCCCC' ,background:'linear-gradient(to top, #190000, #330000)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === "silverVermelho" && <FaCircleCheck />}</div>
                                <div id="silverVerde" onClick={()=>setCorState("silverVerde")} style={{border: '2px solid #CCCCCC' ,background:'linear-gradient(to top, #001900, #003300)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === "silverVerde" && <FaCircleCheck />}</div>
                                <div id="silverAzul" onClick={()=>setCorState("silverAzul")} style={{border: '2px solid #CCCCCC' ,background:'linear-gradient(to top, #000D19, #001A33)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === "silverAzul" && <FaCircleCheck />}</div>
                                <div id="silverRoxo" onClick={()=>setCorState("silverRoxo")} style={{border: '2px solid #CCCCCC' ,background:'linear-gradient(to top, #110019, #220033)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === "silverRoxo" && <FaCircleCheck />}</div>
                              {/* <div id="silverAmarelo" onClick={()=>setCorState("silverAmarelo")} style={{border: '2px solid #CCCCCC' ,background:'linear-gradient(to top, #191500, #332B00)',color:'#FFf'}} className="Color">{corState === "silverAmarelo" && <FaCircleCheck />}</div>*/}
                            
                            </div>

                        </div>

                        </div>

                        

                        <div className="cores--estilo">
                        Complementares
                            <div className="cores--cores">
                                
                                <div id="vermelhoVerde" onClick={()=>setCorState("vermelhoVerde")} style={{border: '2px solid #FF0000' ,background:'linear-gradient(to top, #001900, #003300)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === "vermelhoVerde" && <FaCircleCheck />}</div>
                                <div id="laranjaCiano" onClick={()=>setCorState("laranjaCiano")} style={{border: '2px solid #FF8000' ,background:'linear-gradient(to top, #001919, #003333)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === "laranjaCiano" && <FaCircleCheck />}</div>
                                <div id="verdeVermelho" onClick={()=>setCorState("verdeVermelho")} style={{border: '2px solid #00FF00' ,background:'linear-gradient(to top, #190000, #330000)',color:'#FFf', borderRadius:'16px'}} className="Color">{corState === "verdeVermelho" && <FaCircleCheck />}</div>
                                
                            
                            </div>

                        </div>

                

                    </div>
                    <div className='buttonsEdit'>
                            <div className='cancelButtonEdit'onClick={()=> {setEditCor(false); setCorState(tema)}}>Cancelar</div>
                            <div className='saveButtonEdit' onClick={atualizarTemaFirestore}>Salvar</div>
                        </div>
                        </div>}

                </div>

                <div className='bgly' style={{borderRadius:'16px', color:'#fff', padding:'16px', display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'start'}}>
                        <div className='descricaotittle' style={{ fontSize:'24px', height:'45px', gap:'24px'}}>
                            Fonte
                            {!editFonte &&<div className='personalizar--fonte' style={{fontFamily: fonteState === 'roboto' ? 'Roboto' : fonteState === 'slab' ? 'Roboto Slab' :  fonteState === 'serif' ? 'Roboto serif' : fonteState === 'alegreya' ? 'Alegreya': fonteState === 'rajdhani' ? 'Rajdhani': fonteState === 'playpen' ? 'Playpen Sans': fonteState === 'suse' ? 'SUSE': fonteState === 'josefin' ? 'Josefin Sans': fonteState === 'playfair' ? 'Playfair Display': fonteState === 'nunito' ? 'Nunito': '', padding: '0px 16px 0px 16px'}}>{fonteState}</div>}
                            {!editFonte &&<div className='nomeContainer--buttonEditDK' onClick={()=> {setEditFonte(true); setEditCor(false); setEditEstilo(false); }}>Editar<BiEdit style={{fontSize:'18px'}}/></div>}
                        </div> 
                        
                        {editFonte && <div className='persoBoxEdit'>
                        <div className="fonte" style={{ display:'flex', flexDirection:'column', justifyContent: 'center', alignItems:'center', gap:'8px' }}>
                        
                            <div  style={{ display:'flex', flexDirection:'row', justifyContent: 'center', alignItems:'center', gap:'8px' }}>
                                <div  className={`fonte--bt ${fonteState === "roboto" ? "ftActive" : ""}`} onClick={()=>setFonteState('roboto')} style={{fontFamily:"Roboto",fontWeight:'400'}}>roboto</div>
                                <div  className={`fonte--bt ${fonteState === "slab" ? "ftActive" : ""}`} onClick={()=>setFonteState('slab')} style={{fontFamily:"Roboto slab",  fontWeight:'400'}}>slab</div>
                                <div  className={`fonte--bt ${fonteState === "serif" ? "ftActive" : ""}`} onClick={()=>setFonteState('serif')} style={{fontFamily:"Roboto serif",  fontWeight:'400'}}>serif</div>
                                <div  className={`fonte--bt ${fonteState === "alegreya" ? "ftActive" : ""}`} onClick={()=>setFonteState('alegreya')} style={{fontFamily:"Alegreya",  fontWeight:'400'}}>alegreya</div>
                                <div  className={`fonte--bt ${fonteState === "rajdhani" ? "ftActive" : ""}`} onClick={()=>setFonteState('rajdhani')} style={{fontFamily:"Rajdhani",  fontWeight:'400'}}>rajdhani</div>
                            </div>

                            <div  style={{ display:'flex', flexDirection:'row', justifyContent: 'center', alignItems:'center', gap:'8px' }}>
                                <div  className={`fonte--bt ${fonteState === "playpen" ? "ftActive" : ""}`} onClick={()=>setFonteState('playpen')} style={{fontFamily:"Playpen Sans",  fontWeight:'400'}}>playpen</div>
                                <div  className={`fonte--bt ${fonteState === "josefin" ? "ftActive" : ""}`} onClick={()=>setFonteState('josefin')} style={{fontFamily:"Josefin Sans",  fontWeight:'400'}}>josefin</div>
                                <div  className={`fonte--bt ${fonteState === "playfair" ? "ftActive" : ""}`} onClick={()=>setFonteState('playfair')} style={{fontFamily:"Playfair Display",  fontWeight:'400'}}>playfair</div>
                                <div  className={`fonte--bt ${fonteState === "nunito" ? "ftActive" : ""}`} onClick={()=>setFonteState('nunito')} style={{fontFamily:"Nunito",  fontWeight:'400'}}>nunito</div>
                                <div  className={`fonte--bt ${fonteState === "suse" ? "ftActive" : ""}`} onClick={()=>setFonteState('suse')} style={{fontFamily:"SUSE",  fontWeight:'400'}}>suse</div>
                            </div>
                            
                            
                        </div>

                        <div className='buttonsEdit'>
                            <div className='cancelButtonEdit'onClick={()=> {setEditFonte(false); setFonteState(fonte)}}>Cancelar</div>
                            <div className='saveButtonEdit' onClick={atualizarFonteFirestore}>Salvar</div>
                        </div>
                        </div>}

                </div>

                <div className='bgly' style={{borderRadius:'16px', color:'#fff', padding:'16px', display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'start'}}>
                        <div className='descricaotittle' style={{  height:'45px', gap:'24px'}}>
                            <spam style={{fontSize:'24px', }}>Estilo de bordas</spam>
                            {!editEstilo && <img className='personalizar--estilo' style={{width:'60px', height:'60px', borderRadius:'0'}} src={estiloState ? estiloK[estiloState] || '' : ''} />}
                            {!editEstilo &&<div className='nomeContainer--buttonEditDK'onClick={()=> {setEditEstilo(true); setEditCor(false); setEditFonte(false);}}>Editar<BiEdit style={{fontSize:'18px'}}/></div>}
                        </div> 
                       
                        {editEstilo &&<div className='persoBoxEdit'>
                        <div className='estilo'>

                            <div id='estiloN' onClick={()=>setEstiloState('estiloN')}  className={`estilo--container ${estiloState === "estiloN" ? "ftActive" : ""}`}  style={{ width:'100px', height:'100px'}}>
                                <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Festilos%2F01%20none.png?alt=media&token=8f2ae227-56e9-480e-ada2-16274cde4446&_gl=1*14hlihh*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5NjI4MDkxOC4xOTUuMS4xNjk2MjgyMjAwLjYwLjAuMA..'
                                alt=''/>
                            </div>

                            <div id='estilo01' onClick={()=>setEstiloState('estilo1')}  className={`estilo--container ${estiloState === "estilo1" ? "ftActive" : ""}`}  style={{ width:'100px', height:'100px'}}>
                                <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2001%2Fst1.svg?alt=media&token=4f6af557-6ee0-4e35-a2fa-9d85734c5669'
                                alt=''/>
                            </div>

                            <div id='estilo02' onClick={()=>setEstiloState('estilo2')}  className={`estilo--container ${estiloState === "estilo2" ? "ftActive" : ""}`} style={{ width:'100px', height:'100px'}}>
                                <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2002%2Fst2.svg?alt=media&token=87606c53-1ae1-421a-9f02-92480b5a95d6'
                                alt=''/>
                            </div>

                            <div id='estilo03' onClick={()=>setEstiloState('estilo3')}  className={`estilo--container ${estiloState === "estilo3" ? "ftActive" : ""}`} style={{ width:'100px', height:'100px'}}>
                                <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fst3.svg?alt=media&token=5e12306e-a64a-439b-82cb-58bd37e464fa'
                                alt=''/>
                            </div>

                            <div id='estilo04' onClick={()=>setEstiloState('estilo4')}  className={`estilo--container ${estiloState === "estilo4" ? "ftActive" : ""}`} style={{ width:'100px', height:'100px'}}>
                                <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2004%2Fst4.svg?alt=media&token=231c33d9-f345-4e65-92b4-e19d4ae9c3bf'
                                alt=''/>
                            </div>

                            <div id='estilo05' onClick={()=>setEstiloState('estilo5')}  className={`estilo--container ${estiloState === "estilo5" ? "ftActive" : ""}`} style={{ width:'100px', height:'100px'}}>
                                <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2005%2Fst5.svg?alt=media&token=5baed75c-d347-43f9-aca3-6c39cedd31a3'
                                alt=''/>
                            </div>

                            <div id='estilo06' onClick={()=>setEstiloState('estilo6')}  className={`estilo--container ${estiloState === "estilo6" ? "ftActive" : ""}`} style={{ width:'100px', height:'100px'}}>
                                <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2006%2Fst6.svg?alt=media&token=55dfe06d-004e-474c-bd82-ae3ea32a491b'
                                alt=''/>
                            </div>

                            <div id='estilo07' onClick={()=>setEstiloState('estilo7')}  className={`estilo--container ${estiloState === "estilo7" ? "ftActive" : ""}`} style={{ width:'100px', height:'100px'}}>
                                <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2007%2Fst7.svg?alt=media&token=fab04978-d860-4d2f-a306-2f7d189bc2ad'
                                alt=''/>
                            </div>

                            <div id='estilo08' onClick={()=>setEstiloState('estilo8')}  className={`estilo--container ${estiloState === "estilo8" ? "ftActive" : ""}`} style={{ width:'100px', height:'100px'}}>
                                <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2008%2Fst8.svg?alt=media&token=d3b59a47-78d3-43b4-8eac-c481ed008f1c'
                                alt=''/>
                            </div>

                            {/*<div id='estilo09' onClick={()=>setEstiloState('estilo9')}  className={`estilo--container ${estiloState === "estilo9" ? "ftActive" : ""}`} style={{ width:'100px', height:'100px'}}>
                                <img className='estilo--img' src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/glifos%2Fstyle%2003%2Fst3.svg?alt=media&token=5e12306e-a64a-439b-82cb-58bd37e464fa'
                                alt=''/>
                            </div>*/}

                          

                        </div>
                        <div className='buttonsEdit'>
                            <div className='cancelButtonEdit'onClick={()=> {setEditEstilo(false); setEstiloState(estilo)}}>Cancelar</div>
                            <div className='saveButtonEdit'onClick={atualizarEstiloFirestore}>Salvar</div>
                        </div>
                        </div>}

                </div>


            </div>
       
        </div>
    )
}


export default P05PersonalizarDK;



