import Header from "../../../components/Header";
import React, { useState, useEffect} from "react";
import { BsQrCode } from "react-icons/bs";
import { AiFillDatabase} from "react-icons/ai";
import { BiSolidUserPin, BiSupport } from "react-icons/bi";
import { BsPhoneFill, BsBookHalf } from "react-icons/bs";
import { HiColorSwatch } from "react-icons/hi";
import { FaMoneyCheckDollar, FaGift } from "react-icons/fa6";
import { LuFileStack } from "react-icons/lu";
import { ImExit } from "react-icons/im";
import Suporte from "../P07Suporte";
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/app';
import { getAuth, signOut } from "firebase/auth";
import { CSSTransition, TransitionGroup } from 'react-transition-group'; 



import 'firebase/auth'; 
import AssOferta from "../P06AssOferta";





function Plataforma2( { nome, perfil, componenteAtivo, setComponenteAtivo, createdTime, modoTest, val, MostrarOferta } ){
    console.log(createdTime)
    const [mostrarSuporte, setMOstrarSuporte] = useState(false);
    const navigate = useNavigate();
    const [opacity, setOpacity] = useState(1);
    const [mostrarOferta, setMostrarOferta] = useState(false);
    const [oferta, setOferta] = useState(false);

    const calculateDaysLeft = () => {
        const now = new Date().getTime();
    
        // Adicione esta verificação
        const valTimestamp = val instanceof Date ? val.getTime() : new Date(val).getTime();
        
        const difference = valTimestamp - now;
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        
        return days;
    };
    
    
      const [daysLeft, setDaysLeft] = useState(calculateDaysLeft());

      useEffect(() => {
        if (createdTime && createdTime.seconds) {
            const timestampAtual = new Date().getTime() / 1000;
            const createdTimestamp = createdTime.seconds;
            const novoTimestamp = createdTimestamp + (48 * 3600);
          
            if (timestampAtual <= novoTimestamp) {
                setOferta(true);
            }
        }
    }, [createdTime]);
    

   
    const handleLogout = () => {
        const auth = getAuth();
        signOut(auth)
        .then(() => {
            navigate('/login');
        })
        .catch((error) => {
            console.error("Erro ao deslogar: ", error);
        });
    };

    const handleBorderClick = (value) => {
        setOpacity(0);
        setTimeout(() => {
            setComponenteAtivo(value);
        }, 400);
    };
    
    function formataDataV(dataTimestamp) {
        if (!dataTimestamp || !dataTimestamp.seconds) {
            // Retorne uma mensagem de erro ou um valor padrão
            return "Data não disponível";
        }
    
        const meses = [
            "Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"
        ];
    
        // Converte o timestamp para milissegundos
        const data = new Date(dataTimestamp.seconds * 1000);
    
        // Adiciona dois dias à data
        data.setDate(data.getDate() + 2);
    
        // Obtém o dia, mês e ano
        const dia = data.getDate();
        const mes = meses[data.getMonth()];
        const ano = data.getFullYear();
    
        // Retorna a data formatada
        return `${dia} ${mes} ${ano}`;
    }
    
   
      
      const dataValFormatada = formataDataV(createdTime);

      const mostrarOferta1 = oferta && modoTest;

      function abrirLink() {
        window.open('https://ktalusink.notion.site/Manual-Ktalus-Ink-97f4687e1f4d4ac3a01a45a54400fa04?pvs=4', '_blank');
      }

    return(
        <>
            
            <div className="" >
            {/*<Header nome={nome} perfil={perfil} />*/}
            

           
             
            

            <div className='plataformaV2'  >

                <div className="logoK">
                <img style={{ width:'180px'}}  src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fnew%2Flogotipo-hKtalus_color_%40150.webp?alt=media&token=0d28d2d1-cd3d-4802-a82d-0bf210f532d2" alt=""/>
                </div>


                <div id="plat02" className={ componenteAtivo === "catalogo" ? "plataforma-botaoV2Act": 'plataforma-botaoV2'} onClick={() => handleBorderClick("catalogo")}>
                    <AiFillDatabase style={{fontSize:'26px'}}/>
                    Catálogo
                    </div>

                <div id="plat04" className={ componenteAtivo === "pgInicial" ? "plataforma-botaoV2Act": 'plataforma-botaoV2'} onClick={() => handleBorderClick("pgInicial")}>
                     <BsPhoneFill style={{fontSize:'26px'}}/>
                    Página da Bio
                    </div>

                

                <div id="plat03" className={ componenteAtivo === "perfil" ? "plataforma-botaoV2Act": 'plataforma-botaoV2'} onClick={() => handleBorderClick("perfil")}>
                    <BiSolidUserPin style={{fontSize:'26px'}}/>
                    Perfil     
                    </div>

                <div id="plat01" className={ componenteAtivo === "link" ? "plataforma-botaoV2Act": 'plataforma-botaoV2'} onClick={() => handleBorderClick("link")}>
                   <BsQrCode style={{fontSize:'26px'}}/>
                    Link / QR Code
                    </div>

                    <div id="plat05" className={ componenteAtivo === "personalizar" ? "plataforma-botaoV2Act": 'plataforma-botaoV2'} onClick={() => handleBorderClick("personalizar")}>
                    <HiColorSwatch style={{fontSize:'26px'}}/>
                    Personalizar
                   </div>

                <div id="plat06" className={ componenteAtivo === "assinatura" ? "plataforma-botaoV2Act": 'plataforma-botaoV2'} onClick={() => handleBorderClick("assinatura")}>
                 <FaMoneyCheckDollar style={{fontSize:'26px'}}/>
                    Assinatura
                    </div>

                <div id="plat07" className="plataforma-botaoV2" onClick={abrirLink}>
                 <BsBookHalf style={{fontSize:'26px'}}/>
                    Manual
                    </div>

          
                <div id="plat08" className="plataforma-botaoV2" onClick={() => setMOstrarSuporte(true)}>
                    <BiSupport style={{fontSize:'26px'}}/>
                     Suporte
                    </div>

                <div style={{ }}>
                
                {/*{modoTest && <div className="regressivo">
           {daysLeft > 0 ? (
    <div>Seu período de teste expira em <span style={{fontWeight: 700}}>{`${daysLeft} dias`}</span></div>
) : daysLeft === 0 ? (
    <div>Seu período de teste expira <span style={{fontWeight: 700}}>HOJE</span></div>
) : (
    <div>Seu período de teste expirou</div>
)}
            </div>}*/}

            {mostrarOferta1 && 
                    <div className="ofertaDk">
                        <div className="oferta--tx1dk">
                            <div className="oferta--tx">
                                <FaGift style={{ fontSize: '40px' }} />
                                Você tem uma oferta disponível!
                            </div>

                            <div className="oferta--valdk">Válido até {dataValFormatada}</div>
                        </div>

                        <div className="oferta--btdk" onClick={()=>setMostrarOferta(true)}>Conferir</div>
                    
                    {mostrarOferta && <AssOferta setMostrarOferta={setMostrarOferta}/>}
                    </div>

                }
 
                <div className='perfil2'>
                <div className="borderUser">
               <img className="fotoPerfil" src={perfil || 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fnew%2Fperfil%20off.svg?alt=media&token=59b40760-ce6a-48e8-a0ba-35d2e9adcbbf'} alt=''/>
           </div>
        <p className="nomeEstudio">{nome}</p>
           
        <div id="plat09" className="plataforma-botaoExit2" onClick={handleLogout}>
                    <ImExit style={{fontSize:'20px'}}/>
                        Sair
                    </div>
        </div>
                </div> 
                
                
            </div>

            
            {mostrarSuporte && <Suporte onClose={()=>setMOstrarSuporte(false)}/>}
            </div>

        
        </>
    )
}


export default Plataforma2;