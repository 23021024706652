import './progress.css';
import PropTypes from 'prop-types';


const Progresso = ({progress}) => {
   return( <div className="bgProgress">
    <div className="progress">
        <div className='progress--logo'>
            <img className='progress-img'
            src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fnew%2Ficon-ktalus%40150px.webp?alt=media&token=1a5b8fa0-94fa-448e-be45-c76cca121431' 
            alt=''/>
            
        </div>

        <div className='progress--container'>
            <div className='progress--barra' style={{ width: `${progress}%` }}/>
        </div>

    </div>
</div>)
}

Progresso.propTypes = {
    progress: PropTypes.number.isRequired,
  };


export default Progresso;