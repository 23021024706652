import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import '../Home.css';
import '../../bio/bts.css'
import Plataforma from "../Plataforma";
import P01Link from "../P01Link";
import { getFirestore, collection, query, where, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import P02Catalogo from "../P02Catalogo";
import P03Perfil from "../P03Perfil";
import P04PaginaInicial from "../P04PaginaInicial";
import P05Personalizar from "../P05Personalizar";
import P06Assinatura from "../P06Assinatura";
import { useNavigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import "../../../components/C_Body/Items/items.css";




import Progresso from "../../../components/C_Body/Items/progress";
import ProgressoSave from "../../../components/C_Body/Items/saveProgress";
import P10SDI from "../P10 SDI";
import Plataforma2 from "./Plataforma2";
import P02Catalogo2 from "./P02CatalogoDK";
import P01LinkDK from "./P01LinkDK";
import P03PerfilDK from "./P03PerfilDK";
import P04PaginaInicialDK from "./P04PaginaInicialDK";
import P06AssinaturaDK from "./P06AssinaturaDK";
import P05PersonalizarDK from "./P05PersonalizarDK";
import AssOferta from "../P06AssOferta";
import DeleteTattoo from "../../../components/C_Body/Items/DeleteTattoo";
import EditTattoo from "../../../components/C_Body/Items/EditTattoo";
import EditSecao from "../../../components/C_Body/Items/EditSecao";
import DeleteSecao from "../../../components/C_Body/Items/DeleteSecao";

function Home3() {
    const navigate = useNavigate();
    const [dadosEstudio, setDadosEstudio] = useState({});
    const [componenteAtivo, setComponenteAtivo] = useState("progresso");  // Inicialmente definido como "progresso"
    const [emailUsuario, setEmailUsuario] = useState(null);
    const [progress, setProgress] = useState(0);
    const [createdTime, setCreatedTime] = useState(null);
    const [mostrarOferta, setMostrarOferta] = useState(false);
    const [editTattooVisible, setEditTattooVisible] = useState(false);
    const [deleteTattooVisible, setDeleteTattooVisible] = useState(false);
    const [tattooSelecionada, setTattooSelecionada] = useState(null);
    const [secaoSelecionada, setSecaoSelecionada] = useState(null);
    const [editSecaoVisible, setEditSecaoVisible] = useState(false);
  const [deleteSecaoVisible, setDeleteSecaoVisible] = useState(false);

    const handleTattooSelect = (tattooData) => {
        setTattooSelecionada(tattooData);
        console.log("Dados da Tattoo selecionada no Bisavô:", tattooData);
    };

    const handleSecaoSelect = (secaoData) => {
        setSecaoSelecionada(secaoData);
        console.log("Dados da Secao selecionada no Bisavô:", secaoData);
    };

    const openEditTattoo = () => {
        setEditTattooVisible(true);
      };
    
      const closeEditTattoo = () => {
        setEditTattooVisible(false);
      };
    
      const openDeleteTattoo = () => {
        setDeleteTattooVisible(true);
      };
    
      const closeDeleteTattoo = () => {
        setDeleteTattooVisible(false);
      };


      const openEditSecao = () => {
        setEditSecaoVisible(true);
      };
    
      const closeEditSecao = () => {
        setEditSecaoVisible(false);
      };
    
      const openDeleteSecao = () => {
        setDeleteSecaoVisible(true);
      };
    
      const closeDeleteSecao = () => {
        setDeleteSecaoVisible(false);
      };


      useEffect(() => {
        if (componenteAtivo === "progresso") {
            setTimeout(simulateProgress, 1000);
        }

// Obtém uma referência para o Firestore
const db = getFirestore();
const auth = getAuth();

// Verifica se o usuário está autenticado
const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
    if (user) {
        // O usuário está autenticado, você pode acessar o UID assim:
        const uid = user.uid;
        const email = user.email;
        setEmailUsuario(email);

        // Cria uma consulta para a coleção "estudio" onde o campo "userID" é igual ao UID do usuário
        const q = query(collection(db, 'estudio'), where('userID', '==', uid));

        // Registra um ouvinte para receber atualizações em tempo real
        const unsubscribeFirestore = onSnapshot(q, (querySnapshot) => {
            if (!querySnapshot.empty) {
                // Obtém o primeiro documento (você pode ajustar isso de acordo com sua necessidade)
                const doc = querySnapshot.docs[0];
                updateDadosEstudio(doc);
            } else {
                // Lide com o cenário em que não há documentos correspondentes (por exemplo, defina os dados do estúdio como vazio)
                setDadosEstudio({});
            }
        });

        // Consulta para verificar o campo 'pos' no documento da coleção 'user'
        const userDocRef = doc(db, 'user', uid);
        const userDocUnsubscribe = onSnapshot(userDocRef, (userDoc) => {
            if (userDoc.exists()) {
                const userData = userDoc.data();
                if (userData.pos === false) {
                    navigate("/pos");
                }

                
            
                const userCreatedTime = userData.created_time;
                setCreatedTime(userCreatedTime);

                // Obtém o campo 'estudio' do documento da coleção "user"
                const estudioId = userData.estudio;

                // Cria uma referência para o documento da coleção "estudio" com base no 'estudioId'
                const estudioDocRef = doc(db, 'estudio', estudioId);

                // Atualiza o campo 'lastAccess' do documento da coleção "estudio" com a data e hora atual
                updateDoc(estudioDocRef, { lastAccess: new Date() });
            }
            
        });


        // Certifique-se de desinscrever os ouvintes quando o componente for desmontado
        return () => {
            unsubscribeAuth();
            unsubscribeFirestore();
            userDocUnsubscribe();
        };
    } else {
        // O usuário não está logado, redirecione para a página de login ou faça outra coisa
        console.log('Usuário não está logado');
        // Redirecionar para a página de login, se necessário
        navigate("/login");
    }
});

        // Certifique-se de fazer a limpeza das funções de retorno quando o componente for desmontado
        return () => {
            unsubscribeAuth();
            // Certifique-se de também desinscrever o ouvinte do Firestore aqui
            // Isso garante que o ouvinte seja desativado quando o componente for desmontado
        };
    }, []);
    

     // Função para simular o progresso
     const simulateProgress = () => {
        let currentProgress = 0;
        const intervalId = setInterval(() => {
            currentProgress += 20;  // Supondo que cada função incrementa o progresso em 10%
            setProgress(currentProgress);
            if (currentProgress === 100) {
                clearInterval(intervalId);
                // Aguarda 1 segundo antes de mudar para o componente Plataforma
                setTimeout(() => {
                    setComponenteAtivo("catalogo");
                }, 1000);
            }
        }, 100);  // Supondo que cada função leva 100ms para ser executada
    };


    // Função para atualizar os dados do estúdio
    const updateDadosEstudio = (doc) => {
        if (doc.exists()) {
            const data = doc.data();
            console.log("Dados do Estúdio:", data);
            setDadosEstudio(data);
        } else {
            // Lide com o cenário em que o documento não existe (por exemplo, defina os dados do estúdio como vazio)
            setDadosEstudio({});
        }
    };

   

    return (
        <div className="" >

           
           
            <TransitionGroup>
                {componenteAtivo === "progresso" && (
                    <CSSTransition
                    timeout={300}
                    classNames="fade"
                    key="progresso"
                    unmountOnExit
                    >
                    <Progresso progress={progress} />
                    </CSSTransition>
                )}
            </TransitionGroup>

            {editSecaoVisible && (
        <EditSecao scID={secaoSelecionada.scID} nome={secaoSelecionada.nmSecao} handleCancelar={closeEditSecao}  />
      )}

      {deleteSecaoVisible && (
        <DeleteSecao eID={secaoSelecionada.eID} scID={secaoSelecionada.scID} nome={secaoSelecionada.nmSecao} handleCancelar={closeDeleteSecao} />
      )}

            {deleteTattooVisible &&  <DeleteTattoo ttID={tattooSelecionada.id} nome={tattooSelecionada.nomeT} eID={tattooSelecionada.eID} scID={tattooSelecionada.scID}
        img1={tattooSelecionada.img1} img2={tattooSelecionada.img2} img3={tattooSelecionada.img3} img4={tattooSelecionada.img4} handleCancelar={closeDeleteTattoo}/>}
                
                {editTattooVisible &&  <EditTattoo ttID={tattooSelecionada.id} nomeT={tattooSelecionada.nomeT} eID={tattooSelecionada.eID} scID={tattooSelecionada.scID}
 stTamanho={tattooSelecionada.stTamanho} tam={tattooSelecionada.tam} stPreco={tattooSelecionada.stPreco} preco={tattooSelecionada.preco} stDesc={tattooSelecionada.stDesc} descr={tattooSelecionada.descr}
        img1={tattooSelecionada.img1} img2={tattooSelecionada.img2} img3={tattooSelecionada.img3} img4={tattooSelecionada.img4} handleCancelar={closeEditTattoo}/>}
    
                <div className="main1">
                <div style={{ margin: '0 auto 0 auto' ,position:'absolute', bottom:'8px', color:'rgba(255,255,255,0.3)', fontSize:'12px' }}>© 2024 Ktalus - Todos os direitos reservados</div>
                    <div>
                        <Plataforma2 
                        nome={dadosEstudio.nomeEstudio} modoTest={dadosEstudio.modoTest} val={dadosEstudio.dataVal}
                        perfil={dadosEstudio.perfil} setComponenteAtivo={setComponenteAtivo} componenteAtivo={componenteAtivo}
                        createdTime={createdTime}/>
                    </div>
                    
                    <div className="plataformaV2P2">
                    {mostrarOferta && <AssOferta setMostrarOferta={setMostrarOferta}/>}
                        {componenteAtivo === "link" && <P01LinkDK 
                        perfil={dadosEstudio.perfil} 
                        nick={dadosEstudio.nickname} setComponenteAtivo={setComponenteAtivo} MostrarOferta={mostrarOferta}/>}

                        {componenteAtivo === "catalogo" && <P02Catalogo2
                        estudio={dadosEstudio.iD_estudio} user={dadosEstudio.userID} 
                        setComponenteAtivo={setComponenteAtivo} openDeleteTattoo={openDeleteTattoo} closeDeleteTattoo={closeDeleteTattoo} 
                        openEditTattoo={openEditTattoo} closeEditTattoo={closeEditTattoo} onTattooSelect={handleTattooSelect} onSecaoSelect={handleSecaoSelect} 
                        openDeleteSecao={openDeleteSecao} closeDeleteSecao={closeDeleteSecao}
            openEditSecao={openEditSecao} closeEditSecao={closeEditSecao}/>}

{componenteAtivo === "perfil" && <P03PerfilDK
            estudio={dadosEstudio.iD_estudio}
            user={dadosEstudio.userID} 
            nome={dadosEstudio.nomeEstudio}
            whats={dadosEstudio.whatsapp}
            nick={dadosEstudio.nickname}
            perfil={dadosEstudio.perfil}
            setComponenteAtivo={setComponenteAtivo}/>}

            {componenteAtivo === "pgInicial" && <P04PaginaInicialDK
            estudio={dadosEstudio.iD_estudio} nome={dadosEstudio.nomeEstudio}
            cImg1={dadosEstudio.imgBio1} cImg2={dadosEstudio.imgBio2} cImg3={dadosEstudio.imgBio3}
            cImg4={dadosEstudio.imgBio4} cImg5={dadosEstudio.imgBio5} cImg6={dadosEstudio.imgBio6}
            cImg7={dadosEstudio.imgBio7} sobre={dadosEstudio.sobre} descricao={dadosEstudio.descricao} capaSobre={dadosEstudio.capaSobre} endereco={dadosEstudio.endereco}
            setComponenteAtivo={setComponenteAtivo}/>}

            {componenteAtivo === "personalizar" && <P05PersonalizarDK
            estudio={dadosEstudio.iD_estudio} perfil={dadosEstudio.perfil}
            nome={dadosEstudio.nomeEstudio} tema={dadosEstudio.tema} fonte={dadosEstudio.fonte} estilo={dadosEstudio.estilo}
            setComponenteAtivo={setComponenteAtivo}/>}

            {componenteAtivo === "assinatura" && <P06AssinaturaDK
            estudio={dadosEstudio.iD_estudio} email={emailUsuario}
            modoTest={dadosEstudio.modoTest} statusConta={dadosEstudio.statusConta} plano={dadosEstudio.plano}
            dataConta={dadosEstudio.dataConta} dataVal={dadosEstudio.dataVal} proxCobranca={dadosEstudio.proxCobranca}
            lastPagData={dadosEstudio.lastPagData} lastPagValor={dadosEstudio.lastPagValor}
            
            setComponenteAtivo={setComponenteAtivo}/>}
                    </div>
                </div>
            

            

            

            {componenteAtivo === "SDI" && <P10SDI 
             setComponenteAtivo={setComponenteAtivo}/>}
            

        </div>
        

    )
        
}

export default Home3;
