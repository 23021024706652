import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import { db } from '../.../../../services/firebaseConfig'; // Verifique o caminho correto para sua configuração do Firebase

function Clientes() {
  const [estudios, setEstudios] = useState([]);

  // Função para buscar os dados de estúdios e as respectivas sessões e tatuagens
  const fetchData = () => {
    const estudioRef = collection(db, 'estudio');

    // Usando onSnapshot para ouvir as mudanças em tempo real
    const unsubscribe = onSnapshot(estudioRef, async (snapshot) => {
      const estudioData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Itera sobre cada estúdio encontrado e busca as sessões e tatuagens correspondentes
      const estudiosComSSeTT = await Promise.all(estudioData.map(async (estudio) => {
        const estudioID = estudio.id;

        // Busca a quantidade de sessões para o estúdio
        const sessoesRef = collection(db, 'sessoes');
        const sessoesQuery = query(sessoesRef, where("EstudioID", "==", estudioID));
        const sessoesSnapshot = await getDocs(sessoesQuery);
        const SS = sessoesSnapshot.size;

        // Busca a quantidade de tatuagens para o estúdio
        const tattoosRef = collection(db, 'tattoos');
        const tattoosQuery = query(tattoosRef, where("EstudioID", "==", estudioID));
        const tattoosSnapshot = await getDocs(tattoosQuery);
        const TT = tattoosSnapshot.size;

        // Retorna o estúdio com as informações adicionais de SS e TT
        return {
          ...estudio,
          SS,
          TT
        };
      }));

      // Atualiza o estado com os estúdios e suas respectivas informações de SS e TT
      setEstudios(estudiosComSSeTT);
    });

    // Limpa o ouvinte quando o componente é desmontado
    return () => unsubscribe();
  };

  // useEffect para buscar os dados quando o componente é carregado
  useEffect(() => {
    fetchData();
  }, []);

  // Função para formatar a data para DD/MM/AA com verificações
  const formatDate = (timestamp) => {
    if (!timestamp) {
      console.log("Data não encontrada ou nula:", timestamp);
      return '';
    }

    // Verifica se o campo é um Timestamp do Firebase e converte para Date
    const jsDate = timestamp.toDate ? timestamp.toDate() : timestamp;

    // Tenta formatar a data
    const formattedDate = new Date(jsDate).toLocaleDateString('pt-BR');
    console.log("Data formatada:", formattedDate); // Log da data formatada
    return formattedDate;
  };

  // Função para excluir o estúdio e suas dependências
  const handleDelete = async (estudio) => {
    const estudioID = estudio.id;
    const userID = estudio.userID;

    try {
      // Apaga todas as tatuagens associadas ao EstudioID
      const tattoosQuery = query(collection(db, 'tattoos'), where("EstudioID", "==", estudioID));
      const tattoosSnapshot = await getDocs(tattoosQuery);
      const deleteTattoos = tattoosSnapshot.docs.map((doc) => deleteDoc(doc.ref));
      await Promise.all(deleteTattoos);

      // Apaga todas as sessões associadas ao EstudioID
      const sessoesQuery = query(collection(db, 'sessoes'), where("EstudioID", "==", estudioID));
      const sessoesSnapshot = await getDocs(sessoesQuery);
      const deleteSessoes = sessoesSnapshot.docs.map((doc) => deleteDoc(doc.ref));
      await Promise.all(deleteSessoes);

      // Apaga o documento do usuário
      await deleteDoc(doc(db, 'user', userID));

      // Apaga o documento do estúdio
      await deleteDoc(doc(db, 'estudio', estudioID));

      console.log(`Estúdio ${estudio.nomeEstudio} e dados relacionados excluídos com sucesso.`);

      // Atualiza o estado removendo o estúdio excluído
      setEstudios((prev) => prev.filter((e) => e.id !== estudioID));
    } catch (error) {
      console.error("Erro ao excluir o estúdio:", error);
    }
  };

  return (
    <div className="clientes-container">
      <div className="clientes-box">
        <table className="clientes-tabela">
          <thead>
            <tr style={{ color: '#dffc01' }}>
              <th>Cliente</th>
              <th>eID</th>
              <th>User</th>
              <th>SS</th>
              <th>TT</th>
              <th>Status</th>
              <th>Plano</th>
              <th>Pagamento</th>
              <th>Validade</th>
              <th>Excluir</th>
            </tr>
          </thead>
          <tbody>
            {estudios.map((estudio) => (
              <tr key={estudio.id}>
                <td>{estudio.nomeEstudio}</td>
                <td>{estudio.id}</td>
                <td>{estudio.userID}</td>
                <td>{estudio.SS}</td>
                <td>{estudio.TT}</td>
                <td>{estudio.statusConta ? 'Ativo' : 'Inativo'}</td>
                <td>{estudio.plano === 'yearly' ? 'Anual' : estudio.plano === 'monthly' ? 'Mensal' : estudio.plano === 'Teste' ? 'Teste' : estudio.plano}</td>
                <td>{formatDate(estudio.lastPagData)}</td> {/* Formata a data de pagamento */}
                <td>{formatDate(estudio.dataVal)}</td> 
                <td onClick={() => handleDelete(estudio)}>
                  Excluir
                </td>   
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Clientes;
