import React, { useEffect, useState, useRef } from 'react';
import '../../../components/C_Body/Items/items.css'

import { BsFileImage, BsTrash } from "react-icons/bs";
import { AiFillCloseSquare } from "react-icons/ai";
import { BiEdit, BiImageAdd } from "react-icons/bi";
import firebase from 'firebase/app';
import "firebase/firestore";
import DefinirCrs from '../P04DefinirCrs';
import { updateDoc, getDocs, query, where, getFirestore, collection, doc, } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { db } from '../../../services/firebaseConfig';
import CropEasy16x9Sobre from '../crop/CropEasy16x9Sobre';
import P04Carrossel from '../P04Carrossel';

function P04PaginaInicialDK ({ estudio, nome, setComponenteAtivo, cImg1, cImg2, cImg3, cImg4, cImg5, cImg6, cImg7, sobre, descricao, capaSobre, endereco }){
   

    const [editDescricao, setEditDescricao] = useState(false)

    const updateImgBioField = async (estudioId, field) => {
        try {
          const db = getFirestore();
          const estudioRef = doc(db, "estudio", estudioId);
          
          await updateDoc(estudioRef, {
            [field]: ""
          });
      
          console.log("Campo atualizado com sucesso");
        } catch (error) {
          console.error("Erro ao atualizar o campo: ", error);
        }
      };

     
      const updateSobreField = async (estudioId, value) => {
        try {
          const db = getFirestore();
          const estudioRef = doc(db, "estudio", estudioId);
          
          await updateDoc(estudioRef, {
            "sobre": value
          });
      
          console.log("Campo 'sobre' atualizado com sucesso");
        } catch (error) {
          console.error("Erro ao atualizar o campo 'sobre': ", error);
        }
    };

    const [descricaoState, setDescricaoState] = useState(descricao || "");

    useEffect(() => {
        setDescricaoState(descricao);
        
    }, [descricao]);

    const atualizarDescricaoFirestore = async () => {
        try {
            const estudioRef = doc(db, "estudio", estudio);
            await updateDoc(estudioRef, {
                descricao: descricaoState,
            });
    
            console.log("Campo 'descricao' atualizado com sucesso!");
            setEditDescricao(false);
        } catch (error) {
            console.error("Erro atualizando campo 'descricao': ", error);
        }
    };
    
    const atualizarEnderecoFirestore = async () => {
        try {
            const estudioRef = doc(db, "estudio", estudio);
            await updateDoc(estudioRef, {
                endereco: enderecoState,
            });
    
            console.log("Campo 'endereco' atualizado com sucesso!");
            setEditEndereco(false);
        } catch (error) {
            console.error("Erro atualizando campo 'endereco': ", error);
        }
    };
    


   // A função para lidar com o pressionamento de teclas no textarea
// Esta função é acionada quando o usuário pressiona uma tecla enquanto o foco está no textarea
const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        setDescricaoState((prevState) => `${prevState}\n`);
    }
};

// A função para lidar com mudanças normais no textarea
// Esta função é acionada quando o valor do campo descricao muda
const handleNomeChange = (e) => {
    const valor = e.target.value;
    if (valor.length <= 800) {
        setDescricaoState(valor);
    }
};

// Ao renderizar o componente, este código formata o texto armazenado em descricaoState
// Ele divide o texto por linhas e insere uma quebra de linha HTML entre elas
const textoFormatado = descricaoState ? descricaoState.split('\n').map((str, index, array) => 
    index === array.length - 1 ? str : <>
        {str}
        <br />
    </>
) : '';


        const removerFoto = async () => {
            if (capaSobre) {
                // Criar uma referência para o arquivo no armazenamento do Firebase
                const storage = getStorage();
                const fotoRef = ref(storage, `capasSobre/${estudio}.jpg`); // Substitua pelo caminho correto
        
                // Excluir o arquivo
                try {
                    await deleteObject(fotoRef);
        
                    // Agora, atualize o campo 'perfil' para vazio na base de dados
                    const estudioRef = doc(db, "estudio", estudio);
                    await updateDoc(estudioRef, {
                        capaSobre: "",
                    });
        
                    console.log("Foto e campo de perfil atualizados com sucesso");
        
                } catch (error) {
                    console.error("Erro ao excluir foto: ", error);
                }
            }
        };

const [globalCroppedPhotoURL, setGlobalCroppedPhotoURL] = useState(null);
const [selectedFile, setSelectedFile] = useState(null);
const [openCrop, setOpenCrop] = useState(false);
const [croppedPhotoURL, setCroppedPhotoURL] = useState(globalCroppedPhotoURL);

const fileInputRef = useRef(null);

useEffect(() => {
  setGlobalCroppedPhotoURL(croppedPhotoURL);
}, [croppedPhotoURL, setGlobalCroppedPhotoURL]);

const handleClickUpload = () => {
  fileInputRef.current.click();
};

const handleFileChange = (e) => {
    const file = e.target.files[0];
    const maxFileSize = 20 * 1024 * 1024; // 10 MB em bytes
  
    if (file) {
      // Verifique o tamanho do arquivo
      if (file.size <= maxFileSize) {
        setSelectedFile(file);
        setOpenCrop(true);
      } else {
        alert('O arquivo é muito grande! Por favor, selecione um arquivo de no máximo 10 MB.');
      }
    }
  };
  

const handleRemove = () => {
  setSelectedFile(null); 
  setCroppedPhotoURL(null); 
  setOpenCrop(false); 
  if (fileInputRef.current) {
    fileInputRef.current.value = null;
  }
};

const [editEndereco, setEditEndereco] = useState(false);
const [enderecoState, setEnderecoState] = useState("");

const updateEnderecoField = async (estudioId, value) => {
    try {
      const db = getFirestore();
      const estudioRef = doc(db, "estudio", estudioId);
      
      await updateDoc(estudioRef, {
        "endereco": value
      });
  
      console.log("Campo 'endereco' atualizado com sucesso");
    } catch (error) {
      console.error("Erro ao atualizar o campo 'endereco': ", error);
    }
  };

  const handleEnderecoChange = (e) => {
    const valor = e.target.value;
    if (valor.length <= 100) {
      setEnderecoState(valor);
    }
  };

  
  
  
  useEffect(() => {
    setDescricaoState(descricao);
    setEnderecoState(endereco || "");
  }, [descricao, endereco]);
  


    return(
        <div  style={{fontFamily:'Montserrat', width:'100%'}}>
          
        <div className='pgInicialPage--container' >
            
            <P04Carrossel estudio={estudio} cImg1={cImg1} cImg2={cImg2} cImg3={cImg3} cImg4={cImg4} cImg5={cImg5} cImg6={cImg6} cImg7={cImg7}/>


            <div className=' bgly' style={{ color:'#fff', padding:'16px', width:'100%', borderRadius:'16px', boxSizing:'border-box'}}>

                <div className='saibamais--title' style={{ fontSize:'24px'}}>
                    Botão +INFORMAÇÕES
                    

                 {sobre === true ? (<div className="toggleOnOut active" onClick={() => updateSobreField(estudio, false)}>
                        <p className="toggleOnText">ON</p>
                        <div className="toggleInON"></div>
                    </div>):

                    (<div className="toggleOffOut active" onClick={() => updateSobreField(estudio, true)}>
                        <div className="toggleInOff"></div>
                        <p className="toggleOffText">OFF</p>
                    </div>)}
                </div>
                <hr/>

                <div className='' style={{ display: sobre ? 'flex' : 'none', flexDirection:'row', gap:'8px', marginTop:'16px' }}>
                    {/*CAPA */}
                    <div className=' bgly2' style={{ fontSize:'18px', fontWeight:'700', color:'#E6E6E6', borderRadius:'16px', padding:'16px',
                        display:'flex', flexDirection:'column', gap:'8px', justifyContent:'left', alignItems:'start', width:'224px'
                    }}>
                        Capa da Bio 
                    <input  type="file" id="fotoperfil"  accept=".jpg, .jpeg, .png, .webp" 
                    ref={fileInputRef}  onChange={handleFileChange} style={{ display: 'none' }}/>
                    {!capaSobre ?(<div className='fotoContainer--fotoSMEmpty' onClick={handleClickUpload}>
                           <BiImageAdd style={{fontSize:'40px'}}/> Add foto
                        </div>):

                        (<div style={{display:'flex', flexDirection:'column', alignItems:'center', gap:'8px'}}>
                        <img className='fotoContainer--fotoSM'
                        src={capaSobre} alt=''/>

                        <button className='fotoContainer--remover'  onClick={removerFoto} ><BsTrash />Remover</button></div>)}
                    
                        {openCrop && (
                            <div className={openCrop ? "crop-popup" : "crop-popup-hidden"}>
                            <CropEasy16x9Sobre 
                            photoURL={URL.createObjectURL(selectedFile)} 
                            setOpenCrop={setOpenCrop} 
                            setCroppedPhotoURL={setCroppedPhotoURL} 
                            handleRemove={handleRemove}
                            estudio={estudio}
                            />
                            </div>
                            )}
                    
                    </div>

                    {/*DESCRIÇÃO*/}
                    <div className='bgly2' style={{ display:'flex', flexDirection:'column', alignItems:'start', borderRadius:'16px', padding:'16px', gap:'8px'}}>
                        <div className=''style={{ display:'flex', flexDirection:'row', gap:'8px', fontSize:'18px', color:'#E6E6E6', fontWeight:700 }}>
                            Descrição
                            {!editDescricao && <div className='nomeContainer--buttonEditDK' onClick={()=> setEditDescricao(true)}>Editar<BiEdit style={{fontSize:'18px'}}/></div>}
                        </div>

                        {!editDescricao && <div className='conteudoSobre' >
                            {textoFormatado}
                        </div>}

                        {editDescricao && <div className='' style={{ width:'350px'}}>
                            <div className='nomeEditInput'  >
                                <textarea type='text' name='nomeEdit' style={{height:'200px', borderRadius:'16px', width:'-webkit-fill-available'}}
                              value={descricaoState} onChange={handleNomeChange} onKeyDown={handleKeyDown} /> 
                            <div className='contadorDesc'><span className='caracteres'>{descricaoState ? descricaoState.length : 0}</span>/800</div>
                            </div>
                            <div className='buttonsEdit' style={{marginTop:'0px'}}>
                                <div className='cancelButtonEdit'onClick={()=> setEditDescricao(false)}>Cancelar</div>
                                <div className='saveButtonEdit' onClick={atualizarDescricaoFirestore}>Salvar</div>
                        </div>
                        
                    </div>}


                    </div>


                </div>

                {/* ENDEREÇO */}
<div className='bgly2' style={{ display:'flex', flexDirection:'column', alignItems:'start', borderRadius:'16px', padding:'16px', gap:'8px'}}>
  <div className=''style={{ display:'flex', flexDirection:'row', gap:'8px', fontSize:'18px', color:'#E6E6E6', fontWeight:700 }}>
    Endereço
    {!editEndereco && <div className='nomeContainer--buttonEditDK' onClick={()=> setEditEndereco(true)}>Editar<BiEdit style={{fontSize:'18px'}}/></div>}
  </div>

  {!editEndereco && <div className='conteudoSobre'>
    {enderecoState || "Nenhum endereço cadastrado"}
  </div>}

  {editEndereco && <div className='' style={{ width:'350px'}}>
    <div className='nomeEditInput'>
      <textarea 
        type='text' 
        name='enderecoEdit' 
        style={{height:'100px', borderRadius:'16px', width:'-webkit-fill-available'}}
        value={enderecoState} 
        onChange={handleEnderecoChange} 
      /> 
      <div className='contadorDesc'><span className='caracteres'>{enderecoState ? enderecoState.length : 0}</span>/100</div>
    </div>
    <div className='buttonsEdit' style={{marginTop:'0px'}}>
      <div className='cancelButtonEdit' onClick={() => setEditEndereco(false)}>Cancelar</div>
      <div className='saveButtonEdit' onClick={atualizarEnderecoFirestore}>Salvar</div>
    </div>
  </div>}
</div>


            </div>


        </div>
        
        
        </div>
    )
}


export default P04PaginaInicialDK;



