import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../register/register.css";
import { auth, db } from '../../services/firebaseConfig';
import { getDocs, query, where, collection } from 'firebase/firestore';
import { sendPasswordResetEmail } from "firebase/auth";
import { GoAlertFill } from "react-icons/go";
import { AiFillEyeInvisible, AiFillEye, AiFillCloseCircle, AiFillCheckCircle, AiOutlineLoading3Quarters } from "react-icons/ai";
import { Helmet } from 'react-helmet';

function Login(){
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showIconLogin, setShowIconLogin] = useState(true); // Deixei como true por padrão
    const [loginSuccess, setLoginSuccess] = useState(false); 
    const [showErrorMsg, setShowErrorMsg] = useState(false); // Novo estado para controlar a mensagem de erro
    const [showErrorRst1, setShowErrorRst1] = useState(false);
    const [showErrorRstE, setShowErrorRstE] = useState(false);
    const [showRst, setShowRst] = useState(false);
     

    const [signInWithEmailAndPassword, user, loading, error] = useSignInWithEmailAndPassword(auth);

    const navigate = useNavigate();

    useEffect(() => {
        // Verificar se o usuário já está logado ao carregar a página
        const checkUserLogin = async () => {
            if (auth.currentUser) {
                setLoginSuccess(true);
                setTimeout(() => {
                    localStorage.setItem('user', JSON.stringify(auth.currentUser));
                    navigate("/home");
                }, 0);
            }
        };

        checkUserLogin(); // Chama a função imediatamente

        const unsubscribe = auth.onAuthStateChanged(checkUserLogin); // Adiciona um listener para mudanças no estado de autenticação

        return () => unsubscribe(); // Remove o listener ao desmontar o componente
    }, [navigate]);

    async function handleSignIn(e) {
        e.preventDefault();
        setShowIconLogin(false);

        try {
            await signInWithEmailAndPassword(email, password);
        } catch (error) {
            setShowIconLogin(true);
            setShowErrorMsg(true);

            setTimeout(() => {
                setShowErrorMsg(false);
            }, 2000);
        }
    }



    useEffect(() => {
        if (user) {
            setLoginSuccess(true);
            setTimeout(() => {
                localStorage.setItem('user', JSON.stringify(user));
                navigate("/home");
            }, 2000);
        } else if (error) {  // Verificando se existe um erro
            setShowIconLogin(true); // Mostra o ícone de login novamente
            setShowErrorMsg(true);  // Mostra a mensagem de erro

            setTimeout(() => {
                setShowErrorMsg(false); // Esconde a mensagem de erro após 2 segundos
            }, 2000);
        }
    }, [user, error, navigate]);




    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    async function handleForgotPassword() {
        if (email === '') {
            setShowErrorRstE(true);

            setTimeout(() => {
                setShowErrorRstE(false); // Esconde a mensagem de erro após 2 segundos
            }, 2000);
            // Aqui, você pode definir uma nova mensagem de erro específica para esse caso.
            return;
        }
    
        const userQuery = query(collection(db, "user"), where("email", "==", email));
        const userSnap = await getDocs(userQuery);
    
        if (userSnap.empty) {
            setShowErrorRst1(true);

            setTimeout(() => {
                setShowErrorRst1(false); // Esconde a mensagem de erro após 2 segundos
            }, 2000);
            return;
        }
    
        await sendPasswordResetEmail(auth, email);
        setShowRst(true);
        setTimeout(() => {
            setShowRst(false); // Esconde a mensagem de erro após 2 segundos
        }, 10000);
        // Aqui, você pode definir uma nova mensagem informando que o email foi enviado.
    }

    return(
        <div className="registerP">
            <div style={{position:'absolute', bottom:'8px', color:'rgba(255,255,255,0.3)', fontSize:'12px'}}>© 2023 Ktalus - Todos os direitos reservados</div>
            <Helmet>
        <title>Ktalus Ink</title>
        <meta property="og:title" content="Ktalus Ink" />
        <meta property="og:description" content="Acesso à Plataforma" />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2FKtalusBlack.svg?alt=media&token=17878113-58be-4e5f-9f0d-0f75aaf7ec44&_gl=1*1tkd9rw*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5Nzc0NzQ5Ni4yNjkuMS4xNjk3NzQ3NTAzLjUzLjAuMA.." />
        <meta property="og:url" content="https://ktalusink.pro/login" />
      </Helmet>
           <div className='r--card'>
                <div className='r--header'>
                    <img style={{ width: "180px"}}
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fnew%2Flogotipo-hKtalus_color_%40150.webp?alt=media&token=0d28d2d1-cd3d-4802-a82d-0bf210f532d2"
                    alt="Ktalus"/>
                     <span className="h1sub">O <span style={{ fontWeight:'700', margin:'0px 4px'}}>Catálogo Profissional de Respeito</span> que suas<span style={{color:'#FEEC6E', fontWeight:'700', margin:'0px 4px'}}>Tattoos</span> merecem...</span>
                 </div>

                 {/*<div className='lembrete'>
                    <GoAlertFill style={{fontSize:'48px', marginLeft:'8px'}}/>
                    <div className='alert-text'>No momento, disponível apenas para  <span style={{fontWeight:'700'}}>PROFISSIONAIS CONVIDADOS!</span></div>
                </div>*/}
           
                <div className='r--form'>
                    <div className='form--input'>
                        <div className='label'>Email</div>
                        <input id="email" type="email" name="email" placeholder="Seu email" onChange={(e) => setEmail(e.target.value)}/>
                    </div>

                    <div className='form--input' style={{position:'relative'}}>
                        <div className='label'>Senha</div>
                        <input id="senha" type={showPassword ? 'text' : 'password'} name="senha" placeholder="Sua senha" onChange={(e) => setPassword(e.target.value)}/>
                        <AiFillEyeInvisible
                                className='view2'
                                style={{ display: showPassword ? 'none' : 'block' }}
                                onClick={() => togglePasswordVisibility('password')}
                            />
                            <AiFillEye
                                className='view2'
                                style={{ display: showPassword ? 'block' : 'none' }}
                                onClick={() => togglePasswordVisibility('password')}
                            />
                        <div className='label2' onClick={email ? handleForgotPassword : null}>Recuperar senha</div>
                    </div>

                    

                    
                    <button type="button" className="login" onClick={handleSignIn}> {!showIconLogin ? (
                    <AiOutlineLoading3Quarters className='iconLogin'/>
                ) : loginSuccess ? ( 
                    <AiFillCheckCircle className='iconLogin'/>
                ) : (
                    'Entrar'
                )}</button>
                    

                    

                </div>
                {showRst && <div className='lembrete'>Email com o link para redefinir senha enviado para {email}</div>}
                {showErrorRst1 && <div className='lembrete'>Email não cadastrado</div>}
                {showErrorRstE && <div className='lembrete'>Insira o email que deseja recuperar a senha</div>}
                {showErrorMsg && <div className='lembrete'>Email ou senha incorreto</div>}
            </div>
        </div>
    )
}

export default Login;
