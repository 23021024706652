import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "./register.css";
import "../../App.css";
import { AiFillEyeInvisible, AiFillEye, AiFillCloseCircle, AiFillCheckCircle } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { auth, db } from "../../services/firebaseConfig";
import { useLocation } from "react-router-dom";
import { query, where, getDocs, collection, doc, setDoc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import ProgressoSaveConta from "../../components/C_Body/Items/saveProgressCount";


function Register() {
    const [stage, setStage] = useState('verifying');
    const [isValidInvitation, setIsValidInvitation] = useState(null);
    const navigate = useNavigate();
    const [showProgress, setShowProgress] = useState(false);
    const [progress, setProgress] = useState(0);
    const location = useLocation();
    const invitationId = location.search.substring(1);
    

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                console.log("Usuário logado: ", user);
                // Você pode acessar várias propriedades do usuário como user.email, user.uid, etc.
            } else {
                console.log("Nenhum usuário logado");
            }
        });

        
    
        // Não se esqueça de cancelar a inscrição para evitar vazamentos de memória
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        // ... (outros códigos)
    
        const checkInvitation = async () => {  // Torna a função assíncrona
            if (invitationId) {  // Verifique se invitationId existe
                const docRef = doc(db, 'invitation', invitationId);
    
                // Obtém os dados uma única vez
                const docSnap = await getDoc(docRef);  // Aguarda a obtenção dos dados
    
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    if (data.email === '') {
                        setIsValidInvitation(true);  // Convite é válido
                    } else {
                        setIsValidInvitation('used');  // O convite deste link já foi usado
                    }
                } else {
                    setIsValidInvitation(false);  // Convite link inválido
                }
            } else {
                setIsValidInvitation(false);  // Se invitationId for null ou undefined
            }
        };
        
        checkInvitation();  // Chama a função
    
    }, [invitationId]);

    const [showVerify, setShowVerify] = useState(true);
    const [showConfirm, setShowConfirm] = useState(false);

    useEffect(() => {
        // Esconda #verif após 3 segundos
        const timeout1 = setTimeout(() => setShowVerify(false), 3000);
        
        // Se o convite for válido, mostre #confirm por 0.5 segundos antes de mostrar .registerP
        if (isValidInvitation === true) {
            const timeout2 = setTimeout(() => setShowConfirm(true), 3000);
            const timeout3 = setTimeout(() => setShowConfirm(false), 3500);
            return () => {
                clearTimeout(timeout2);
                clearTimeout(timeout3);
            };
        }

        return () => clearTimeout(timeout1);
    }, [isValidInvitation]);


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showPasswordAlert, setShowPasswordAlert] = useState(false);
    const [showPasswordAlert2, setShowPasswordAlert2] = useState(false);
    const [showEmailAlert, setShowEmailAlert] = useState(false);

    const [
        createUserWithEmailAndPassword,
        user,
        loading,
        error,
    ] = useCreateUserWithEmailAndPassword(auth);

    // Função para alternar a visibilidade das senhas
    const togglePasswordVisibility = (type) => {
        if (type === 'password') {
            setShowPassword(!showPassword);
        } else {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    const handleSignUp = async (e) => {
        e.preventDefault();

         // Novo: Verificação para garantir que a senha tem pelo menos 8 caracteres
    if (password.length < 8 || confirmPassword.length < 8) {
        setShowPasswordAlert2(true);
        setTimeout(() => setShowPasswordAlert2(false), 5000);
        return;
    }

    // Ação 1: Verifique se as senhas são iguais
    if (password !== confirmPassword) {
        setShowPasswordAlert(true);
        setTimeout(() => setShowPasswordAlert(false), 5000);
        return;
    }

        // Ação 2: Verificar se o email já está registrado
        try {
            const q = query(collection(db, 'user'), where('email', '==', email));
            const querySnapshot = await getDocs(q);
    
            if (!querySnapshot.empty) {
                setShowEmailAlert(true);
                setTimeout(() => setShowEmailAlert(false), 5000);
                return;
            }
        } catch (err) {
            console.error("Erro ao verificar o email:", err);
        }

        // Ação 3: Registrar o usuário
        try {
            setShowProgress(true); 


            const userCredential = await createUserWithEmailAndPassword(email, password);
            const { user } = userCredential;
            
            // Cria um novo documento na coleção 'user'
            if (user) {
                setProgress(10);
                const currentTime = new Date(); 

                await setDoc(doc(db, 'user', user.uid), {
                    email: user.email,
                    uid: user.uid,
                    created_time: currentTime,
                    pos: false
                });
                setProgress(70);
                const invitationDocRef = doc(db, 'invitation', invitationId);
            await updateDoc(invitationDocRef, {
                email: user.email
            });
            setProgress(100);
            setTimeout(() => {
                navigate('/pos');
            }, 2000);
            }
        } catch (err) {
            console.error("Erro ao criar o usuário:", err);
            console.error("Detalhes do erro:", err.response ? err.response.data : 'Sem detalhes disponíveis');
        }finally {
            setShowProgress(false);  // Esconder o componente ProgressoSaveConta após a conclusão da Ação 3
        }
    };

    if (isValidInvitation === null) {
        return ;
    }

    return (
        <>
        {/*
        {stage === 'verifying' &&
        ()}

        
        
        */}

        

{showVerify ? (
                <div id="verif" className="bgVerif">
                <div className="verif--circle"></div>
                <BsSearch style={{position:'absolute', zIndex:1004, fontSize:'32px', marginBottom:'72px'}}/>
                <div className="verif--text">Verificando<br/>Link-Convite...</div>
            </div>
            ) : showConfirm ? (
                <div id='confirm' className="bgVerif">
            
            <AiFillCheckCircle style={{ zIndex:1004, fontSize:'80px', color:'#71FE6E'}}/>
            <div className="verif--text">Link-Convite<br/>Válido</div>
        </div>
            ) : isValidInvitation === true ? (
         
        
        <div className="registerP">
            <div style={{position:'absolute', bottom:'8px', color:'rgba(255,255,255,0.3)', fontSize:'12px'}}>© 2024 Ktalus - Todos os direitos reservados</div>
{showProgress && <ProgressoSaveConta progress={progress} />}
            <div className="r--card">
                <div className='r--header'>
                    <img style={{ width: "255px"}}
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fnew%2Flogotipo-hKtalus_color_%40150.webp?alt=media&token=0d28d2d1-cd3d-4802-a82d-0bf210f532d2"
                    alt="Ktalus"/>
                    <h1 className="h1sub">O <span style={{ fontWeight:'700', margin:'0px 4px'}}>Catálogo de Respeito</span> que<br/> suas<span style={{color:'#FEEC6E', fontWeight:'700', margin:'0px 4px'}}>Tattoos</span> merecem...</h1>
                 </div>
                

            <div className="r--form">
                
                <div className='form--input'>
                    <div className='label'>Email</div>
                    <input  id="email" type="email"  name="email"
                        placeholder="Seu email"  onChange={(e) => setEmail(e.target.value)}
                    />
                    </div>

                    {showEmailAlert && <div className="alert">Já existe registro com este email!</div>}
                    <div className='form--input'>
                    <div className='label'>Senha</div>
                        <div className="senha">
                            <input
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                autoComplete="off"
                                placeholder="********"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <AiFillEyeInvisible
                                className='view'
                                style={{ display: showPassword ? 'none' : 'block' }}
                                onClick={() => togglePasswordVisibility('password')}
                            />
                            <AiFillEye
                                className='view'
                                style={{ display: showPassword ? 'block' : 'none' }}
                                onClick={() => togglePasswordVisibility('password')}
                            />
                            
                        </div> 
                        <div className="infoSenha">min. 8 caracteres</div>
                        </div>
                        {showPasswordAlert2 && <div className="alert">A senha precisa conter pelo menos 8 caracteres!</div>}
                       
                        <div className='form--input'>
                        <div className='label'>Confirmar senha</div>
                        <div className="senha">
                            <input
                                id="ConfirmPassword"
                                type={showConfirmPassword ? 'text' : 'password'}
                                name="confirmPassword"
                                autoComplete="off"
                                placeholder="********"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <AiFillEyeInvisible
                                className='view'
                                style={{ display: showConfirmPassword ? 'none' : 'block' }}
                                onClick={() => togglePasswordVisibility('confirmPassword')}
                            />
                            <AiFillEye
                                className='view'
                                style={{ display: showConfirmPassword ? 'block' : 'none' }}
                                onClick={() => togglePasswordVisibility('confirmPassword')}
                            />
                        </div>

                        </div>
                        {showPasswordAlert && <div className="alert">Senhas não estão iguais!</div>}
                        
                        <div className="TUPP">Ao clicar em 'INICIAR TESTE DE 7 DIAS' você afirma que leu e concorda com os <a href="https://ktalusink.pro/kter" target="_blank" rel="noopener noreferrer" style={{color:'#dffc01', fontSize:'12px',fontWeight:700, textDecoration:'underline'}}>Termos de uso</a> e <a href="https://ktalusink.pro/kpol" target="_blank" rel="noopener noreferrer" style={{color:'#dffc01', fontSize:'12px',fontWeight:700, textDecoration:'underline'}}>Política de privacidade</a></div>
                        <button type="button" className="login" onClick={handleSignUp}>
                            INICIAR TESTE DE 7 DIAS
                        </button>
                   
                </div>
            </div>
        </div>):isValidInvitation === 'used' ? (
        <div className="bgVerif">
            
        <AiFillCloseCircle style={{ zIndex:1004, fontSize:'80px', color:'#FE6E6E'}}/>
        <div className="verif--text">Este Link-Convite<br/>já foi usado!</div>
    </div>
    ) : (
        <div className="bgVerif">
            
            <AiFillCloseCircle style={{ zIndex:1004, fontSize:'80px', color:'#FE6E6E'}}/>
            <div className="verif--text">Link-Convite<br/>inválido</div>
        </div>
    )}
    </>
    );
}

export default Register;
